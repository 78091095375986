
import abi from "./contract/GlobalPool.json";

import Buy from "./component/buy";

import "./copy.png";
import "./logo.png";


import { useState, useEffect } from "react";
import { ethers } from "ethers";

function App() {

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[upd,setupd]=useState("None");

  const[refer,setrefer]=useState("None");
  const[dd,setDD]=useState("None");
 const[referlink,setreflink]=useState("None");

const[pack,setpackage]=useState("None");
const[t1,sett1]=useState("None");
const [tasks,setTasks] = useState([]);
const[p1,setp1]=useState("None");
const[p2,setp2]=useState("None");
const[p3,setp3]=useState("None");
const[p4,setp4]=useState("None");
const[p5,setp5]=useState("None");
const[p6,setp6]=useState("None");
const[p7,setp7]=useState("None");
const[p8,setp8]=useState("None");
const[p9,setp9]=useState("None");
const[p10,setp10]=useState("None");

         


useEffect(() => {
  const connectWallet = async () => {
    const contractAddress = "0x7fa281e724d542c0cacffe18675940ab30829b18";
    const contractABI = abi.abi;
    const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    
    
    document.querySelector("#name").value=myParam;
if(myParam == null)
{
  document.querySelector("#name").value="0x38f3C1d9B7c8D52E32a5Ec14D7E6635428fb1B34";
}
    try {
      const { ethereum } = window;
      document.querySelector("#test").value = "UPGRADE USDT 20";
      document.querySelector("#ppa").value  ="WELCOME! TO ZEXAGLOBAL FAMILY, UPGRADE TO USDT 20 TO BOOK SLOTS FOR FIRST LEVEL";

      if (ethereum) {
        const account = await ethereum.request({
          method: "eth_requestAccounts",
         
        });
       
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });

        window.ethereum.on("accountsChanged", () => {
          
          window.location.reload();
        });
        const provider = new ethers.providers.Web3Provider(ethereum);
        try {
          const selectedNetwork = await provider.getNetwork();
        
          if (selectedNetwork.chainId !== 56) {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
            });
            console.log("You have switched to the Binance network");
          } else {
            console.log("You are already on the Binance network");
          }
        } catch (switchError) {
          // The network has not been added to MetaMask
          if (switchError.code === 4902) {
            console.log("Please add the Binance network to MetaMask");
          } else {
            console.log("Cannot switch to the network");
          }
        }
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer 
        );
        setAccount(account);
        setState({ provider, signer, contract });

        
        document.querySelector("#us").value  = account[0];
        let alltasks = await contract.getMemos(account[0]);
        setTasks(alltasks);
        const b =Number(alltasks[2]);
        const refer = (alltasks[1]);
       setrefer(refer);
       document.querySelector("#hid").value  = (alltasks[1]);
       const directs =Number(alltasks[2]);
       setDirects(directs);

       const direct =Number(alltasks[7])/1000000000000000000;
       setDirect(direct);
     

       const pack =Number(alltasks[6]);
       setpackage(pack);

         
         const p1 =Number(alltasks[8]) * 14.25;
         setp1(p1);
         const p2 =Number(alltasks[9]) * 19;
         setp2(p2);
         const p3 =Number(alltasks[10]) * 38;
         setp3(p3);
         const p4 =Number(alltasks[11]) * 76;
         setp4(p4);
         const p5 =Number(alltasks[12]) * 152;
         setp5(p5);
         const p6 =Number(alltasks[13]) * 304;
         setp6(p6);
         const p7 =Number(alltasks[14]) * 608;
         setp7(p7);
         const p8 =Number(alltasks[15]) * 1216;
         setp8(p8);
         const p9 =Number(alltasks[16]) * 2432;
         setp9(p9);
         
         
         document.querySelector("#pp").value  = Number(alltasks[0]);
         
   
         setB(b);
         const referlink = 'https://zexaglobal.com/?ref='+ account;
         setreflink(referlink);
         
         const upd = p1 + p2 +p3 + p4 + p5 + p6 + p7 + p8 + p9;
         setupd(upd);
         const t1 = direct + upd;
         sett1(t1);



         document.querySelector("#link").value =referlink;
          if(pack==20)
          { 
            document.querySelector("#test").value = "Upgrade USDT 30";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 30 TO BOOK FURTHER SLOTS FOR SECOND LEVEL";
          }
          else if(pack==50)
          { 
            document.querySelector("#test").value = "Upgrade USDT 40";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 40 TO BOOK FURTHER SLOTS FOR THIRD LEVEL";

          
          }
          else if(pack ==90)
          {
            document.querySelector("#test").value = "Upgrade USDT 80";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 80 TO BOOK FURTHER SLOTS FOR FORTH LEVEL";

          }
          else if(pack ==170)
          {
            
            document.querySelector("#test").value = "Upgrade USDT 160";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 160 TO BOOK FURTHER SLOTS FOR FIFTH LEVEL";

          }
          else if(pack ==330)
          {
            document.querySelector("#test").value = "Upgrade USDT 320";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 320 TO BOOK FURTHER SLOTS FOR SIX LEVEL";

          }
          
          else if(pack ==650)
          {
            document.querySelector("#test").value = "Upgrade USDT 640";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 640 TO BOOK FURTHER SLOTS FOR SEVENTH LEVEL";

          }
            
          else if(pack ==1290)
          {
            document.querySelector("#test").value = "Upgrade USDT 1280";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 1280 TO BOOK FURTHER SLOTS FOR EIGHTH LEVEL";

          }
            
          else if(pack ==2570)
          {
            document.querySelector("#test").value = "Upgrade USDT 2560";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 2560 TO BOOK FURTHER SLOTS FOR NINTH LEVEL";

          }

          else if(pack ==5130)
          {
            document.querySelector("#test").value = "Upgrade USDT 5120";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, UPGRADE TO USDT 5120 TO BOOK FURTHER SLOTS FOR TENTH LEVEL";

          }
          
          else if(pack > 10000)
          {
            
      
            document.querySelector("#test").value = "ALL PACKAGE PURCHASED";
            document.querySelector("#ppa").value  ="CONGRATULATIONS! YOU ARE NOW PROUD MEMBER OF ZEXAGLOBAL FAMILY, YOU HAD SUCCESSFULLY UPGRADED ALL PACKAGES";

          }
          
  
     
        } else {
          alert("Please install metamask");
        }

      } catch (error) {
        console.log(error);
      }
      
     
    };
    
     
    
    
    connectWallet();
    
  }, []);

 
  // console.log(state);
        
       
  return (
   
<div style={{backgroundColor:"black"}}>
<input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
   

    <div class="page-container" >
 
    <table style={{color: "white",textAlign :"center",verticalAlign:"center", width :"100%",  backgroundColor:"white ",
}}>
            
      <tr><td>  
              
               <table width="100%">
               <tr>
               <td style={{width:"3%" ,}}></td>
               <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <img src ="logo.png" width="300px" height="200px" />
  </td>
  <td style={{width:"3%" ,}}></td>
  </tr>

     <tr><td style={{width:"3%" ,}}></td>
               <td style={{  verticalAlign:"center", width :"94%",backgroundColor:"#B30B04", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <marquee> <input type="textarea"  id ="ppa" style={{ width:"100%", border:"0", backgroundColor:"#B30B04",color: "white", }} disabled></input></marquee>
  </td>
  <td style={{width:"3%" ,}}></td>
  </tr>        

         </table>      
           
             
         <div class="clearfix"> </div>	
         <table width="100%"  >

<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <h6>REFER LINK - {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
  navigator.clipboard.writeText(referlink);}} /></button></h6>
  <br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h6>MY SPONSOR - {refer}</h6>
  <br></br>
  <td style={{width:"3%" ,}}></td>
  </td>
  

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
   <input type ="text" style={{backgroundColor: "#B30B04" ,textAlign:"center", border :"0", color:"white" } }id ="test"  disabled></input>
  <Buy state={state} /> 
<br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
</table>


          <table width="100%">
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>TOTAL PACKAGE PURCHASED</h5>
<h6>  USDT {pack}  </h6><br></br> </td> 

<td style={{width:"3%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>

<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
 
  <h5>MY DIRECT TEAM</h5>
<h6>   {directs}  </h6><br></br> </td>

<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>

<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
 
  <h5>MY DIRECT INCOME</h5>
<h6>   {direct}  </h6><br></br> </td>

<td style={{width:"3%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>MY TOTAL INCOME</h5>
<h6>  USDT {t1}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>MY UPGRADE INCOME</h5>
<h6>  USDT {upd}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - II INCOME</h5>
<h6>  USDT {p1}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - III INCOME</h5>
<h6>  USDT {p2}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - IV INCOME</h5>
<h6>  USDT {p3}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - V INCOME</h5>
<h6>  USDT {p4}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - VI INCOME</h5>
<h6>  USDT {p5}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - VII INCOME</h5>
<h6>  USDT {p6}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - VIII INCOME</h5>
<h6>  USDT {p7}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - IX INCOME</h5>
<h6>  USDT {p8}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#B30B04 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>LEVEL - X INCOME</h5>
<h6>  USDT {p9}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>


</table>

           
                          
    
    <div class="clearfix"></div>
  
  <table style={{width:"100%" ,}}><tr><td  style={{color: "#B30B04" , textAlign :"center",verticalAlign:"center", }}>
	 © 2024 ZEXAGLOBAL . All Rights Reserved  </td></tr></table>
   </td></tr></table>

</div>
</div>


       

           
                   
	




     

  );
}

export default App;
